import React from 'react'

import { PageHeader } from '../../components/common/styles'

import Gallery from '../../components/common/gallery';

const Haiti = () => (
  <Gallery
    imageLinks={
      [1, 2, 3, 4, 5, 6, 7].map((number) => (
        `https://s3.amazonaws.com/valentina-site/portfolio_images/haiti-brown/Haiti-Brown${number}.jpg`
      ))
    }
    navigationColor='#c3c6cc'
    fromText='Portfolio'
    fromLink='/portfolio'
    moreInfo={{
      linkText: 'How It Works',
      content:
        <HaitiHowItWorks />
    }}
  />
)

const HaitiHowItWorks = () => (
  <div style={{ display: 'flex', alignItems: 'center', height: '100%', padding: '40px' }}>
    <div style={{
      width: '30%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      height: '50%',
    }}>
      <img style={{ width: '40%' }} src='https://s3.amazonaws.com/valentina-site/portfolio_images/haiti-brown/how_it_works/banana1.jpg' />
      <img style={{ width: '40%' }} src='https://s3.amazonaws.com/valentina-site/portfolio_images/haiti-brown/how_it_works/banana2.jpg' />
    </div>
    <div style={{ width: '70%' }}>
      <PageHeader style={{ color: '#cc8e3b', textTransform: 'unset' }}>
        HAITI - Colors in the mainstream life
    </PageHeader>
      <p>
        While working in Haiti at the D.O.T center, my co-worker and friend Ishita Mehta and I
        decided to develop a small capsule collection that illustrated the beautiful and colorful
        imagery of contemporary Haiti.
    </p>
      <p>
        Being both from developing countries ( India and Colombia), we know what it is to live
        around countries stereotypes. With this Haitian Collection, we aim to communicate reality-
        the great experience we had working there and how Haiti, a country that is often undervalued,
        is full of joy, color, happiness and art. We know that beyond aesthetical beauty,
        design is a tool to correctly communicate masses.
    </p>
    </div>
  </div >
)

export default Haiti